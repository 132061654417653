import React, { useState, useEffect, useCallback } from "react";
import api from "../api.js";

import GlobalLoadingSpinner from "../components/GlobalSpinner.jsx";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { useNavigate } from "react-router-dom";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { DateTime } from "luxon";

import ConversationSummary from "../components/ConversationSummary.jsx";

const Actions = () => {
  const navigate = useNavigate();

  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState("open");

  const setFilterAndResetPage = status => {
    setFilter(status);
    setPage(1);
  };

  const filterSelected = value => {
    return value === filter;
  };

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [totalConversationsOverall, setTotalConversationsOverall] = useState(0);

  const totalPages = Math.ceil(totalConversationsOverall / pageSize);

  const handlePageSizeChange = event => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(prevPage => Math.max(prevPage - 1, 1));
    }
  };

  const fetchConversations = useCallback(async () => {
    if (filter === "None") {
      return;
    }

    const chatbotUuid = localStorage.getItem("chatbot_uuid");

    if (!chatbotUuid) {
      console.error(
        "Chatbot UUID is not found in local storage or URL parameters"
      );
      return;
    }

    try {
      setLoading(true);
      setConversations([]);
      let apiFilters = {};

      switch (filter) {
        case "resolved":
          apiFilters = { resolved: true };
          break;
        case "open":
          apiFilters = { resolved: false };
          break;
      }

      const { data } = await api.get(`/api/conversations/${chatbotUuid}`, {
        params: { page, page_size: pageSize, ...apiFilters },
      });

      setTotalConversationsOverall(data.total_conversations);
      setConversations(data.conversations);
      setLoading(false);
      // scrollToTop();
    } catch (err) {
      console.error(err);
      // TODO: Present error and allow refresh
    }
  }, [filter, page, pageSize]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  const classByResolvedStatus = {
    true: "text-green-700 bg-green-50 ring-green-600/20",
    false: "text-gray-600 bg-gray-50 ring-gray-500/10",
  };

  const badgeClassesByConversation = conversation => {
    return classByResolvedStatus[conversation.resolved];
  };

  const classNames = (...classes) => classes.filter(Boolean).join(" ");

  const resolveConversation = async conversation_uuid => {
    try {
      await api.post("/api/update-conversation", {
        conversation_uuid,
        resolved: true,
      });

      if (filter !== "all") {
        setFilter("None");
      }

      setConversations(
        conversations.map(convo =>
          convo.conversation_uuid === conversation_uuid
            ? { ...convo, resolved: true, last_message_time: DateTime.now() }
            : convo
        )
      );
    } catch (err) {
      console.error("Failed to update conversation", err);
    }
  };

  const reOpenConversation = async conversation_uuid => {
    try {
      await api.post("/api/update-conversation", {
        conversation_uuid,
        resolved: false,
      });

      if (filter !== "all") {
        setFilter("None");
      }

      setConversations(
        conversations.map(convo =>
          convo.conversation_uuid === conversation_uuid
            ? { ...convo, resolved: false, last_message_time: DateTime.now() }
            : convo
        )
      );
    } catch (err) {
      console.error("Failed to update conversation", err);
    }
  };

  const viewConversation = conversation => {
    navigate(`/dashboard/ai-inbox?only=${conversation.conversation_uuid}`);
  };

  const [expandedSummaries, setExpandedSummaries] = useState({});

  const toggleSummary = (e, id) => {
    e.stopPropagation();

    setExpandedSummaries(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="p-4">
      <GlobalLoadingSpinner loading={loading} />
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="min-w-0 flex-1 flex items-center space-x-4">
          <h3 className="text-md font-bold leading-7 text-gray-700 sm:truncate sm:text-xl sm:tracking-tight mb-0">
            Actions
          </h3>

          <div className="h-6 border-l border-gray-300"></div>

          <div className="flex items-center space-x-2 md:space-x-6">
            <button
              type="button"
              onClick={() => setFilterAndResetPage("open")}
              className={`inline-flex items-center border-0 rounded-md px-3 py-2 text-xs font-semibold hover:bg-gray-50 ${
                filterSelected("open") ? "text-emerald-500" : "text-gray-700"
              }`}
            >
              Show Open
            </button>

            <button
              type="button"
              onClick={() => setFilterAndResetPage("resolved")}
              className={`inline-flex items-center border-0 rounded-md px-3 py-2 text-xs font-semibold hover:bg-gray-50 ${
                filterSelected("resolved")
                  ? "text-emerald-500"
                  : "text-gray-700"
              }`}
            >
              Show Closed
            </button>

            <button
              type="button"
              onClick={() => setFilterAndResetPage("all")}
              className={`inline-flex items-center border-0 rounded-md px-3 py-2 text-xs font-semibold bg-white hover:bg-gray-50 ${
                filterSelected("all") ? "text-emerald-500" : "text-gray-700"
              }`}
            >
              Show All
            </button>
          </div>
        </div>
      </div>

      <div className="md:px-5 md:py-2 lg:px-10 lg:py-5 xl:px-20 xl:py-5">
        <ul role="list" className="divide-y divide-gray-100">
          {conversations.map(conversation => (
            <li
              key={conversation.conversation_uuid}
              className="flex items-center justify-between gap-x-6 py-5"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {conversation.user?.first_name
                      ? `${conversation.user.first_name} ${conversation.user.last_name}`
                      : "Unknown User"}
                  </p>
                  <p
                    className={classNames(
                      badgeClassesByConversation(conversation),
                      "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                    )}
                  >
                    {conversation.resolved ? "Closed" : "Open"}
                  </p>
                </div>

                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p
                    onClick={() => {
                      viewConversation(conversation);
                    }}
                  >
                    <ConversationSummary conversation={conversation} />
                  </p>
                </div>
              </div>

              <div className="flex flex-none items-center gap-x-4 hidden sm:flex text-xs">
                <button
                  onClick={() => viewConversation(conversation)}
                  className="font-semibold text-gray-400 bg-gray-50 hover:bg-gray-100 border border-gray-300 p-1 rounded-md"
                >
                  View conversation
                </button>
                {!conversation.resolved ? (
                  <button
                    onClick={() =>
                      resolveConversation(conversation.conversation_uuid)
                    }
                    className="font-semibold text-emerald-600 bg-emerald-50 hover:bg-emerald-200 border border-emerald-300 p-1 rounded-md"
                  >
                    Complete
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      reOpenConversation(conversation.conversation_uuid)
                    }
                    className="font-semibold text-gray-400 bg-gray-50 hover:bg-gray-100 border border-gray-300 p-1 rounded-md"
                  >
                    Re-open
                  </button>
                )}
              </div>

              <div className="flex flex-none items-center gap-x-4 sm:hidden">
                <Menu as="div" className="relative flex-none">
                  <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      aria-hidden="true"
                      className="h-5 w-5"
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <a
                        onClick={() => {
                          viewConversation(conversation);
                        }}
                        className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                      >
                        View conversation
                      </a>
                    </MenuItem>
                    {!conversation.resolved && (
                      <MenuItem>
                        <a
                          onClick={() => {
                            resolveConversation(conversation.conversation_uuid);
                          }}
                          className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          Complete
                        </a>
                      </MenuItem>
                    )}
                    {conversation.resolved && (
                      <MenuItem>
                        <a
                          onClick={() => {
                            reOpenConversation(conversation.conversation_uuid);
                          }}
                          className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          Re-open
                        </a>
                      </MenuItem>
                    )}
                  </MenuItems>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {filter !== "None" && !loading && totalPages > 1 && (
        <div className="flex flex-col items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex items-center">
            <p className="text-sm text-gray-700">
              Page <span className="font-medium">{page}</span> of{" "}
              <span className="font-medium">{totalPages}</span>
            </p>
          </div>

          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <nav
                aria-label="Pagination"
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              >
                <button
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
                </button>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setPage(index + 1)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      page === index + 1
                        ? "bg-emerald-600 text-white"
                        : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    } focus:z-20 focus:outline-offset-0`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={handleNextPage}
                  disabled={page === totalPages}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                </button>
              </nav>
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label htmlFor="page-size" className="mr-2 text-sm text-gray-700">
              Page size:
            </label>
            <select
              id="page-size"
              value={pageSize}
              onChange={handlePageSizeChange}
              className="border-gray-300 rounded-md text-sm p-1.5"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Actions;
