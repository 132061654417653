import { useState, useEffect } from "react";
import api from "../api.js";

export const useCompanyApi = () => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCompany = async () => {
    setLoading(true);
    try {
      const response = await api.get("/api/company");
      setCompany(response.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const updateCompany = async (data) => {
    setLoading(true);
    try {
      const response = await api.put("/api/company", data);
      setCompany(response.data);
      setLoading(false);
      return null;
    } catch (err) {
      setError(err);
      setLoading(false);
      return err;
    }
  };

  return {
    company,
    loading,
    error,
    setError,
    fetchCompany,
    updateCompany,
    setLoading,
  };
};
