import React, { useState } from "react";
import api from "../api.js";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await api.post("/api/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("File uploaded successfully");
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-center py-4">
      <h2 className="text-xl font-semibold mb-4">
        Upload Files to Train Your AI Support Team
      </h2>
      <div className="flex flex-col items-center space-y-4">
        <label
          htmlFor="file-upload"
          className="cursor-pointer py-2 px-4 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
        >
          Choose File
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          className="hidden"
        />
        <button
          onClick={handleFileUpload}
          className={`py-2 px-4 bg-emerald-500 text-white rounded-md ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-emerald-600"}`}
          disabled={loading}
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
