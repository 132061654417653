import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";

import {
  Bars3Icon,
  BellIcon,
  Cog6ToothIcon,
  InboxIcon,
  VideoCameraIcon,
  ArrowUpTrayIcon,
  FunnelIcon,
  RectangleStackIcon,
  LightBulbIcon,
  XMarkIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";

import plainUserAvatar from "../imgs/icons/emptyUserAvatar.png";

import logo from "../logo.png";

import api from "../api.js";

const navigationItems = [
  {
    name: "AI Inbox",
    href: "/dashboard/ai-inbox",
    icon: InboxIcon,
  },
  {
    name: "Actions",
    href: "/dashboard/actions",
    icon: VideoCameraIcon,
  },
  {
    name: "Content",
    href: "/dashboard/content",
    icon: ArrowUpTrayIcon,
  },
  {
    name: "Integrations",
    href: "/dashboard/integrations",
    icon: RectangleStackIcon,
  },
  {
    name: "Test",
    href: "/dashboard/test",
    icon: FunnelIcon,
  },
  {
    name: "Intents",
    href: "/dashboard/intents",
    icon: LightBulbIcon,
  },
  {
    name: "Settings",
    href: "/dashboard/settings",
    icon: WrenchIcon,
  },
];

const userNavigation = [
  { name: "Your profile", href: "/dashboard/profile" },
  { name: "Sign out", action: "signout" },
];

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userImage, setUserImage] = useState(plainUserAvatar);

  useEffect(() => {
    api.get(`/api/user`).then(response => {
      if (response.data && response.data.picture_url) {
        setUserImage(response.data.picture_url);
      }
    });
  }, []);

  useEffect(() => {
    if (
      location.pathname.endsWith("/dashboard/") ||
      location.pathname.endsWith("/dashboard")
    ) {
      navigate("/dashboard/ai-inbox");
    }
  }, [location.pathname, navigate]);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      // Clear relevant data from localStorage and sessionStorage
      localStorage.removeItem("supabase_jwt");
      localStorage.removeItem("supabase.auth.token");
      sessionStorage.removeItem("company_id");
      sessionStorage.removeItem("user_email");
      localStorage.removeItem("chatbot_uuid");
      localStorage.clear();

      // Redirect to login or home page
      navigate("/onboarding/signup");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const currentPath = location.pathname;
  const navigation = navigationItems.map(item => ({
    ...item,
    current: item.href === currentPath,
  }));

  return (
    <div>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-50 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full border border-gray-300"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </button>
              </div>
            </TransitionChild>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 ring-1 ring-white/10">
              <div className="flex h-16 shrink-0 items-center">
                <img src={logo} alt="Papaya" className="h-8 w-auto" />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul className="-mx-2 space-y-1">
                      {navigation.map(item => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-200 text-black"
                                : "text-gray-400 hover:bg-gray-200 hover:text-black",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className="h-6 w-6 shrink-0"
                            />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="mt-auto">
                    <a
                      href="/dashboard/account"
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-200 hover:text-black"
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                      />
                      Account
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 border border-gray-300">
          <div className="flex h-16 shrink-0 items-center">
            <img src={logo} alt="Papaya" className="h-8 w-auto" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-200 text-black" // Active link style for desktop
                            : "text-gray-400 hover:bg-gray-200 hover:text-black", // Hover style for desktop
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                        )}
                      >
                        <item.icon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="mt-auto">
                <a
                  href="/dashboard/account"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-200 hover:text-black"
                >
                  <Cog6ToothIcon
                    aria-hidden="true"
                    className="h-6 w-6 shrink-0"
                  />
                  Account
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72 bg-white">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1"></div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* Separator */}
              <div
                aria-hidden="true"
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <MenuButton className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <img
                    alt="User picture"
                    src={userImage}
                    className="h-11 w-11 rounded-full"
                  />
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 text-gray-400"
                  />
                </MenuButton>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  {userNavigation.map(item => (
                    <MenuItem key={item.name}>
                      {item.action === "signout" ? (
                        <button
                          onClick={handleSignOut}
                          className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          {item.name}
                        </button>
                      ) : (
                        <a
                          href={item.href}
                          className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>

        <main className="bg-white">
          <div className="">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
