import React, { useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  Transition,
} from "@headlessui/react";
import { Fragment } from "react";

// TODO: replace with real options
const pricingOptions = [
  {
    title: "Free",
    price: "$0",
    description: "100 messages/month",
    benefits: ["Basic features"],
  },
  {
    title: "Basic",
    price: "$29",
    description: "2000 messages/month",
    benefits: ["Essential features", "Priority support"],
  },
  {
    title: "Pro",
    price: "$49",
    description: "Unlimited messages",
    benefits: ["Advanced features", "Dedicated support"],
  },
];

const usage = {
  used: 1400,
  limit: 2000,
  renewalDate: "October 31, 2024",
};

const billingHistory = [
  { id: "001", date: "2024-01-15", amount: 29.99 },
  { id: "002", date: "2024-02-15", amount: 29.99 },
];

const cardDetails = {
  number: "4242 4242 4242 4242",
  expiry: "12/20",
  cvv: "123",
};
// ~END~ - TODO: replace with real options

export default function PlanBilling() {
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const openCardModal = () => setIsCardModalOpen(true);
  const closeCardModal = () => setIsCardModalOpen(false);

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const openSubscriptionModal = () => setIsSubscriptionModalOpen(true);
  const closeSubscriptionModal = () => setIsSubscriptionModalOpen(false);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="bg-white shadow sm:rounded-lg p-4">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Your Plan
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Basic Plan - Access to essential features, 2000 messages/month,
              and priority support.
            </p>
          </div>
          <div className="mt-4">
            <button
              onClick={openSubscriptionModal}
              className="inline-flex items-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500"
            >
              Change plan
            </button>
          </div>
        </div>

        <div className="bg-white shadow sm:rounded-lg p-4">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Usage
          </h3>
          <div className="mt-2 text-sm text-gray-500 space-y-2">
            <p>
              <strong>Credits consumed:</strong> {usage.used}.
            </p>
            <p>
              <strong>Subscription limit:</strong> {usage.limit}.
            </p>
            <p>
              Your credits renew at the start of every calendar month but your
              extra credits will remain as it is.
            </p>
            <p>
              Next renewal: <strong>{usage.renewalDate}</strong>.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-lg p-4 col-span-1 sm:col-span-2">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Billing History
        </h3>
        <ul className="mt-2 space-y-2 text-sm text-gray-500">
          {billingHistory.map(invoice => (
            <li key={invoice.id} className="flex justify-between items-center">
              <span>
                Fake Invoice #{invoice.id} - {invoice.date} - $
                {invoice.amount.toFixed(2)}
              </span>
              <a
                onClick={() => alert("Unimplemented!")}
                className="text-emerald-600 hover:underline cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download PDF
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Payment method
          </h3>
          <div className="mt-5">
            <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <h4 className="sr-only">Visa</h4>
              <div className="sm:flex sm:items-start">
                <svg
                  viewBox="0 0 36 24"
                  aria-hidden="true"
                  className="h-8 w-auto sm:h-6 sm:flex-shrink-0"
                >
                  <rect rx={4} fill="#224DBA" width={36} height={24} />
                  <path
                    d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                    fill="#fff"
                  />
                </svg>
                <div className="mt-3 sm:ml-4 sm:mt-0">
                  <div className="text-sm font-medium text-gray-900">
                    Ending with 4242
                  </div>
                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                    <div>Expires 12/20</div>
                    <span
                      aria-hidden="true"
                      className="hidden sm:mx-2 sm:inline"
                    >
                      &middot;
                    </span>
                    <div className="mt-1 sm:mt-0">
                      Last updated on 22 Aug 2024
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                <button
                  onClick={openCardModal}
                  type="button"
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition show={isCardModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCardModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="space-y-4">
                    <DialogTitle
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Card Details
                    </DialogTitle>
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Card Number
                      </label>
                      <input
                        type="text"
                        name="number"
                        value={cardDetails.number}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="flex space-x-4">
                      <div className="w-1/2">
                        <label className="block text-sm font-medium text-gray-700">
                          Expiry
                        </label>
                        <input
                          type="text"
                          name="expiry"
                          value={cardDetails.expiry}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block text-sm font-medium text-gray-700">
                          CVV
                        </label>
                        <input
                          type="text"
                          name="cvv"
                          value={cardDetails.cvv}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="mt-4 flex space-x-2">
                      <button
                        onClick={() => alert("Unimplemented")}
                        className="flex-1 inline-flex justify-center rounded-md bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700"
                      >
                        Save
                      </button>

                      <button
                        type="button"
                        className="flex-1 inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:text-sm"
                        onClick={closeCardModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition show={isSubscriptionModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeSubscriptionModal}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <DialogTitle
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Choose Your Plan
                      </DialogTitle>
                      <div className="mt-2">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                          {pricingOptions.map(plan => (
                            <div
                              key={plan.title}
                              className="border rounded-lg p-4 flex flex-col justify-between"
                            >
                              {" "}
                              {/* Aligned button at the bottom */}
                              <div>
                                <h4 className="text-lg font-semibold">
                                  {plan.title}
                                </h4>
                                <p className="text-sm text-gray-500">
                                  {plan.price}/month
                                </p>
                                <p className="text-sm text-gray-500">
                                  {plan.description}
                                </p>
                                <ul className="mt-2 text-sm text-gray-500 space-y-1">
                                  {plan.benefits.map((benefit, idx) => (
                                    <li key={idx}>- {benefit}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                {plan.title === "Free" ? (
                                  <button className="mt-4 w-full bg-yellow-600 text-white px-3 py-2 rounded-md text-sm font-bold cursor-default">
                                    Current
                                  </button>
                                ) : (
                                  <button
                                    className="mt-4 w-full bg-emerald-600 text-white px-3 py-2 rounded-md text-sm font-semibold hover:bg-emerald-500"
                                    onClick={() =>
                                      alert(
                                        `Unimplemented: Switching to ${plan.title} plan`
                                      )
                                    }
                                  >
                                    Choose ${plan.title}
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:text-sm"
                      onClick={closeSubscriptionModal}
                    >
                      Cancel
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
