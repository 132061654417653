import React, { useState } from "react";
import { authenticateWithParagon } from "../paragonAuthHelper.js";
import api from "../api.js";
import { paragon } from "@useparagon/connect";

const GmailSupport = () => {
  const [loading, setLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      const companyId = sessionStorage.getItem("company_id");
      if (!companyId) {
        console.error("Company ID not found in sessionStorage");
        return;
      }

      await authenticateWithParagon(companyId);
      await paragon.connect("gmail", {
        onSuccess: () => {
          alert("Connected to Gmail successfully");
        },
        onError: error => {
          alert("Error connecting to Gmail");
          console.error("Error connecting to Gmail:", error);
        },
      });
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };

  const callParagonWorkflow = async () => {
    try {
      setLoading(true);
      const response = await paragon.workflow(
        "256e5e7e-c01d-4e2f-97ba-9af28fa51a8a",
        {
          key1: "value1",
          key2: "value2",
        }
      );

      await api.post("/api/save-embedding", {
        data: response,
        source_type: "GMAIL",
      });

      alert("Paragon workflow response sent to backend");
    } catch (error) {
      console.error("Error calling Paragon workflow:", error);
      alert("Error calling Paragon workflow");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-center py-4">
      <h2 className="text-xl font-semibold mb-4">
        Let's grab your Gmail support conversations to train your AI Support
        Team
      </h2>
      <div className="flex flex-col items-center space-y-4">
        <button
          className="py-2 px-4 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
          onClick={handleGoogleSignIn}
        >
          Sign in to Google
        </button>
        <button
          className={`py-2 px-4 bg-emerald-500 text-white rounded-md ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-emerald-600"}`}
          onClick={callParagonWorkflow}
          disabled={loading}
        >
          {loading ? "Uploading..." : "Upload Gmail support history"}
        </button>
      </div>
      <a
        href="https://support.google.com/mail/answer/10759745"
        target="_blank"
        rel="noopener noreferrer"
        className="block mt-4 text-emerald-600 underline"
      >
        How to export email history from Gmail
      </a>
    </div>
  );
};

export default GmailSupport;
