import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api.js";
import { authenticateWithParagon } from "../paragonAuthHelper.js";
import { paragon } from "@useparagon/connect";

const IntercomSupport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleIntercomSignIn = async () => {
    try {
      const companyId = sessionStorage.getItem("company_id");
      if (!companyId) {
        console.error("Company ID not found in sessionStorage");
        return;
      }

      await authenticateWithParagon(companyId);

      paragon.connect("intercom", {
        onSuccess: () => {
          alert("Connected to Intercom successfully");
        },
        onError: error => {
          alert("Error connecting to Intercom");
          console.error("Error connecting to Intercom:", error);
        },
      });
    } catch (error) {
      console.error("Error during Intercom Sign-In:", error);
    }
  };

  const callParagonWorkflow = async () => {
    try {
      setLoading(true);
      const response = await paragon.workflow(
        "5422dcf8-968a-4a8a-b8f1-0c4798dbed56",
        {}
      );

      await api.post("/api/save-embedding", {
        data: response,
        source_type: "INTERCOM",
      });

      alert("Paragon workflow response sent to backend");
    } catch (error) {
      console.error("Error calling Paragon workflow:", error);
      alert("Error calling Paragon workflow");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-center py-4">
      <h2 className="text-xl font-semibold mb-4">
        Let's grab your Intercom support conversations to train your AI Support
        Team
      </h2>
      <div className="flex flex-col items-center space-y-4">
        <button
          className="py-2 px-4 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
          onClick={handleIntercomSignIn}
        >
          Sign in to Intercom
        </button>
        <button
          className={`py-2 px-4 bg-emerald-500 text-white rounded-md ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-emerald-600"}`}
          onClick={callParagonWorkflow}
          disabled={loading}
        >
          {loading ? "Uploading..." : "Upload Intercom support history"}
        </button>
      </div>
      <a
        href="https://support.intercom.com"
        target="_blank"
        rel="noopener noreferrer"
        className="block mt-4 text-emerald-600 underline"
      >
        How to export conversation data from Intercom
      </a>
    </div>
  );
};

export default IntercomSupport;
