import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

import logo from "../logo.png";
import { ReactComponent as BackButtonIcon } from "../imgs/icons/backButton.svg";

import { useIngestionWizard } from "./IngestionWizardContext";

const pricing = {
  frequencies: [
    { value: "monthly", label: "Monthly", priceSuffix: "/month" },
    { value: "annually", label: "Annually", priceSuffix: "/year" },
  ],
  tiers: [
    {
      name: "Free Plan",
      id: "tier-free",
      href: "#",
      price: { monthly: "$0", annually: "$0" },
      description: "",
      features: [
        "100 messages",
        "10 actions",
        "Feature text",
        "Feature text goes here",
      ],
      mostPopular: false,
    },
    {
      name: "Pro Plan",
      id: "tier-pro",
      href: "#",
      price: { monthly: "$29", annually: "$299" },
      description: "",
      features: [
        "1,000 messages",
        "100 actions",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
      ],
      mostPopular: true,
    },
    {
      name: "Growth Plan",
      id: "tier-growth",
      href: "#",
      price: { monthly: "$199", annually: "$499" },
      description: "",
      features: [
        "10,000 messages",
        "5,000 actions",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
      ],
      mostPopular: false,
    },
  ],
};

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const choosePlan = tier => {
  console.log("Subscription tier chosen: ", tier); // TODO: persist choice and move on to payment
};

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const { prevWizardRoute } = useIngestionWizard();
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = "#F0F0EE";
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  const handleBackClick = () => {
    navigate(prevWizardRoute());
  };

  return (
    <div className="bg-[#F0F0EE]">
      <div className="flex flex-col h-screen">
        <div className="flex flex-col h-[60%]">
          <header className="min-h-[80px] w-full h-20 border-b border-gray-300 flex items-center relative">
            <div
              className="absolute left-10 flex items-center cursor-pointer"
              onClick={handleBackClick}
            >
              <BackButtonIcon width={35} />
            </div>
            <div className="flex items-center justify-center flex-grow">
              <img src={logo} alt="Papaya Logo" className="h-auto max-h-full" />
            </div>
          </header>

          <main className="flex-grow flex flex-col items-center justify-center space-y-4">
            <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
              <div className="mx-auto max-w-4xl text-center">
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Pricing plan
                </p>
              </div>
              <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Choose the most adequate plan for your needs
              </p>
              <div className="mt-6 flex justify-center">
                <RadioGroup
                  value={frequency}
                  onChange={setFrequency}
                  className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                >
                  {pricing.frequencies.map(option => (
                    <Radio
                      key={option.value}
                      value={option}
                      className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-emerald-600 data-[checked]:text-white"
                    >
                      {option.label}
                    </Radio>
                  ))}
                </RadioGroup>
              </div>
              <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
                {pricing.tiers.map(tier => (
                  <div
                    key={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "ring-2 ring-emerald-600"
                        : "ring-2 ring-gray-300",
                      "rounded-xl p-8"
                    )}
                  >
                    <h2
                      id={tier.id}
                      className={classNames(
                        tier.mostPopular ? "text-emerald-600" : "text-gray-900",
                        "text-lg font-semibold leading-8 text-center"
                      )}
                    >
                      {tier.name}
                    </h2>
                    <p className="mt-4 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                    <p className="mt-6 flex items-baseline gap-x-1 justify-center">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        {tier.price[frequency.value]}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        {frequency.priceSuffix}
                      </span>
                    </p>
                    <button
                      onClick={() => choosePlan(tier)}
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.mostPopular
                          ? "bg-emerald-600 shadow-sm hover:bg-emerald-500"
                          : "bg-[#8D8D8D]",
                        "w-full text-white mt-6 block rounded-sm px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                      )}
                    >
                      Get started
                    </button>
                    <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                      {tier.features.map(feature => (
                        <li key={feature} className="flex gap-x-3 text-black">
                          <CheckIcon
                            aria-hidden="true"
                            className={classNames(
                              tier.mostPopular
                                ? "text-emerald-600"
                                : "text-[#8D8D8D]",

                              "h-6 w-5 flex-none text-emerald-600"
                            )}
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
