import React, { useState, useEffect } from "react";
import api from "../api.js";
import IntentFormDialog from "../components/IntentFormDialog";

const Intents = () => {
  const [intents, setIntents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingIntent, setEditingIntent] = useState(null);
  const [formData, setFormData] = useState({
    type: "",
    description: "",
    response: "",
  });

  useEffect(() => {
    fetchIntents();
  }, []);

  const fetchIntents = async () => {
    try {
      const chatbotUuid = localStorage.getItem("chatbot_uuid");
      const response = await api.get(`/api/intents`, {
        params: { chatbot_uuid: chatbotUuid },
      });
      setIntents(response.data);
    } catch (err) {
      console.error("Failed to fetch intents", err);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingIntent(null);
    setFormData({
      type: "",
      description: "",
      response: "",
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateOrUpdate = async evt => {
    evt.preventDefault();

    try {
      const chatbotUuid = localStorage.getItem("chatbot_uuid");
      if (editingIntent) {
        await api.put(`/api/intents/${editingIntent.id}`, {
          ...formData,
          chatbot_uuid: chatbotUuid,
        });
      } else {
        await api.post("/api/intents", {
          ...formData,
          chatbot_uuid: chatbotUuid,
        });
      }
      fetchIntents();
      handleCancel();
    } catch (err) {
      console.error("Failed to create or update intent", err);
    }
  };

  const handleEdit = intent => {
    setEditingIntent(intent);
    setFormData({
      type: intent.type,
      description: intent.description,
      response: intent.response,
    });
    showModal();
  };

  const handleRemove = async id => {
    try {
      await api.delete(`/api/intents/${id}`);
      fetchIntents();
    } catch (err) {
      console.error("Failed to remove intent", err);
    }
  };

  const columns = [
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Response", dataIndex: "response", key: "response" },
    {
      title: "Default",
      dataIndex: "default",
      key: "default",
      render: (text, record) => (record.default ? "Yes" : "No"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="space-x-2">
          {!record.default && (
            <>
              <button
                onClick={() => handleEdit(record)}
                className="px-2 py-1 bg-emerald-500 text-white rounded hover:bg-emerald-600"
              >
                Edit
              </button>
              <button
                onClick={() => handleRemove(record.id)}
                className="px-2 py-1 border border-gray-300 text-gray-700 rounded hover:bg-red-50 hover:text-red-600"
              >
                Remove
              </button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full p-4">
      <h1 className="text-2xl font-bold mb-4">Intents</h1>

      <table className="min-w-full bg-white border border-gray-200 rounded-lg">
        <thead>
          <tr>
            {columns.map(col => (
              <th
                key={col.key}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-100"
              >
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {intents.map((intent, index) => (
            <tr
              key={intent.id}
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
            >
              {columns.map(col => (
                <td key={col.key} className="px-6 py-4 whitespace-nowrap">
                  {col.render
                    ? col.render(null, intent)
                    : intent[col.dataIndex]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <button
        onClick={showModal}
        className="mt-4 px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600"
      >
        Create New Intent
      </button>

      {isModalVisible && (
        <IntentFormDialog
          isVisible={isModalVisible}
          editingIntent={editingIntent}
          formData={formData}
          handleInputChange={handleInputChange}
          handleCreateOrUpdate={handleCreateOrUpdate}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Intents;
