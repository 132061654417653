import React, { useState, useEffect } from "react";
import api, { WIDGET_URL } from "../api.js";

const Settings = () => {
  const [chatbotUuid, setChatbotUuid] = useState(localStorage.getItem("chatbot_uuid") || "");
  const [greeting, setGreeting] = useState("");
  const [prompts, setPrompts] = useState("");
  const [activeTab, setActiveTab] = useState("2");

  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [secondaryColor, setSecondaryColor] = useState("#4F8F00");
  const [fontColor, setFontColor] = useState("#000000");

  // TODO: fetch from API
  const [companyName, setCompanyName] = useState("Sample company name");

  // TODO: persist
  const [companyLogo, setCompanyLogo] = useState("https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png");

  const [modalVisible, setModalVisible] = useState(false);
  const [snippet, setSnippet] = useState("");

  useEffect(() => {
    if (!chatbotUuid) {
      console.error("Chatbot UUID is not found in local storage or URL parameters");
      return;
    }

    const fetchSettings = async () => {
      try {
        const response = await api.get(`/api/chatbot/${chatbotUuid}`);
        setGreeting(response.data.greeting);
        setPrompts(response.data.prompt_text || "");
      } catch (err) {
        console.error("Failed to fetch settings", err);
      }
    };

    fetchSettings();
  }, [chatbotUuid]);

  const handleGreetingSubmit = async () => {
    try {
      await api.put(`/api/chatbot/${chatbotUuid}/greeting`, { greeting });
      alert("Greeting updated successfully");
    } catch (err) {
      console.error("Failed to update greeting", err);
      alert("Failed to update greeting");
    }
  };

  const handlePromptsSubmit = async () => {
    try {
      await api.post(`/api/prompts/${chatbotUuid}`, { text: prompts });
      alert("Prompts updated successfully");
    } catch (err) {
      console.error("Failed to update prompts", err);
      alert("Failed to update prompts");
    }
  };

  const copyToClipboard = () => {
    const newSnippet = `
      <iframe
        id="bonsai-widget-iframe"
        src="${WIDGET_URL}/widgetEntry.html"
        title="Live Chat Widget"
      ></iframe>

      <script>
        document.addEventListener("DOMContentLoaded", () => {
          const iframe = document.getElementById("bonsai-widget-iframe");

          Object.assign(iframe.style, {
            border: "none",
            width: "500px",
            height: "650px",
            position: "fixed",
            bottom: "5px",
            right: "5px",
            zIndex: "9999",
          });

          iframe.onload = () => {
            iframe.contentWindow.postMessage(
              {
                type: "SET_CUSTOM_OPTIONS",
                tenantId: "${chatbotUuid}",
                backgroundColor: "${backgroundColor}",
                secondaryColor: "${secondaryColor}",
                fontColor: "${fontColor}",
                companyName: "${companyName}",
                companyLogo: "${companyLogo}"
              },
              "*"
            );
          };
        });
      </script>
    `;
    setSnippet(newSnippet);
    setModalVisible(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(snippet.trim());
    alert("Snippet copied to clipboard!"); // TODO: Make friendlier
    setModalVisible(false);
  };

  useEffect(() => {
    if (activeTab === "2" && chatbotUuid) {
      const iframe = document.getElementById("bonsai-widget-iframe");
      if (iframe) {
        iframe.onload = () => {
          iframe.contentWindow.postMessage(
            {
              type: "SET_CUSTOM_OPTIONS",
              tenantId: chatbotUuid,
              backgroundColor: backgroundColor,
              secondaryColor: secondaryColor,
              fontColor: fontColor,
              companyName: companyName,
              companyLogo: companyLogo,
            },
            "*"
          );
        };
        iframe.src = iframe.src;
      }
    }
  }, [activeTab, chatbotUuid, backgroundColor, secondaryColor, fontColor, companyName, companyLogo]);

  return (
    <div className="w-full max-w-5xl mx-auto p-6 mt-6">
      <h1 className="text-3xl font-bold mb-8">Settings</h1>
      <div className="mb-8">
        <nav className="flex space-x-4">
          <button
            className={`text-lg font-semibold px-3 py-2 rounded-md ${activeTab === "1" ? "bg-emerald-500 text-white" : "bg-gray-100 text-gray-800"}`}
            onClick={() => setActiveTab("1")}
          >
            Agent Instructions
          </button>
          <button
            className={`text-lg font-semibold px-3 py-2 rounded-md ${activeTab === "2" ? "bg-emerald-500 text-white" : "bg-gray-100 text-gray-800"}`}
            onClick={() => setActiveTab("2")}
          >
            Chatbot
          </button>
        </nav>
      </div>

      {activeTab === "1" && (
        <div className="mb-12">
          <div className="mb-8">
            <label className="block text-sm font-semibold mb-2">Greeting</label>
            <textarea
              value={greeting}
              onChange={(e) => setGreeting(e.target.value)}
              rows={4}
              className="w-full border border-gray-300 rounded-md p-2"
            />
            <button
              onClick={handleGreetingSubmit}
              className="mt-3 px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition"
            >
              Save Greeting
            </button>
          </div>

          <div>
            <label className="block text-sm font-semibold mb-2">Prompts</label>
            <textarea
              value={prompts}
              onChange={(e) => setPrompts(e.target.value)}
              rows={4}
              className="w-full border border-gray-300 rounded-md p-2"
            />
            <button
              onClick={handlePromptsSubmit}
              className="mt-3 px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition"
            >
              Save Prompts
            </button>
          </div>
        </div>
      )}

      {activeTab === "2" && (
        <div>
          <h3 className="text-xl font-bold">Tailor the chatbot below. Once done, copy snippet to embed it on your website.</h3>
          <p className="text-sm mt-1 italic">
            * Customizations will not be saved once you leave this page.
          </p>

          <iframe
            id="bonsai-widget-iframe"
            src={`${WIDGET_URL}/widgetEntry.html`}
            title="Live Chat Widget"
            style={{
              border: "none",
              width: "500px",
              height: "650px",
              position: "fixed",
              bottom: "5px",
              right: "5px",
              zIndex: 9999,
            }}
          />

          <fieldset className="my-6 border border-gray-300 rounded-lg">
            <legend className="text-lg font-bold mb-2">Company Details</legend>
            <div className="grid grid-cols-[1fr_3fr] gap-4 mb-4">
              <div className="mb-4">
                <label className="block text-sm font-semibold mb-2">Name</label>
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-semibold mb-2">Logo</label>
                <input
                  type="text"
                  value={companyLogo}
                  onChange={(e) => setCompanyLogo(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
              </div>
            </div>
          </fieldset>

          <fieldset className="mt-2 border border-gray-300 rounded-lg">
            <legend className="text-lg font-bold mb-2">Color scheme</legend>
            <div>
              <div className="grid grid-cols-[1fr_2fr_1fr_2fr] gap-4 mb-4">
                <div>
                  <label className="block text-sm font-semibold mb-2">Background</label>
                  <input
                    type="color"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold mb-2">Title, messages and buttons</label>
                  <input
                    type="color"
                    value={secondaryColor}
                    onChange={(e) => setSecondaryColor(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold mb-2">Font</label>
                  <input
                    type="color"
                    value={fontColor}
                    onChange={(e) => setFontColor(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                  />
                </div>
              </div>

              <button
                type="button"
                onClick={copyToClipboard}
                className="px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition"
              >
                Copy Snippet
              </button>
            </div>
          </fieldset>
        </div>
      )}

      {/* Modal for Snippet */}
      {modalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white rounded-lg p-6 w-11/12 max-w-lg">
            <button
              onClick={() => setModalVisible(false)}
              className="absolute top-5 right-5 text-gray-300 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <h2 className="text-lg font-bold mb-4">
              Snippet Preview
            </h2>
            <textarea
              readOnly
              value={snippet}
              rows={10}
              className="w-full border border-gray-300 rounded-md p-2"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setModalVisible(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleCopy}
                className="px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition"
              >
                Copy to Clipboard
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
