import React, { useState, useEffect } from "react";
import api from "../api"; // Ensure this path is correct based on your project structure

const WebsiteURLInput = ({ onSuccess }) => {
  const [websiteURL, setWebsiteURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [urlList, setUrlList] = useState([]);

  // Fetch the list of uploaded URLs for the chatbot
  const fetchUrls = async () => {
    try {
      const response = await api.get(`/api/uploads`, {
        params: { chatbot_id: Number(sessionStorage.getItem("chatbot_id")) },
      });
      setUrlList(response.data);
    } catch (error) {
      // console.error("Error fetching URL list:", error);
      // alert("Error fetching URL list");
    }
  };

  // Call fetchUrls when the component mounts
  useEffect(() => {
    fetchUrls();
  }, []);

  const handleURLChange = e => {
    setWebsiteURL(e.target.value);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      const response = await api.post("/api/process-url", {
        url: websiteURL,
      });

      if (response.status === 200) {
        alert("URL processed successfully");
        onSuccess();
        fetchUrls(); // Refresh the list after a successful upload
      } else {
        alert("Failed to process URL");
      }
    } catch (error) {
      console.error("Error processing URL:", error);
      alert("Error processing URL");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveClick = async uploadId => {
    try {
      const response = await api.delete(`/api/remove-url/${uploadId}`);
      if (response.status === 200) {
        alert("URL removed successfully");
        fetchUrls(); // Refresh the list after a successful removal
      } else {
        alert("Failed to remove URL");
      }
    } catch (error) {
      console.error("Error removing URL:", error);
      alert("Error removing URL");
    }
  };

  return (
    <div className="max-w-lg mx-auto text-center">
      <h2 className="text-xl font-semibold mb-4">Enter your website URL</h2>
      <input
        type="url"
        placeholder="https://www.example.com/faq"
        value={websiteURL}
        onChange={handleURLChange}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        disabled={loading}
      />
      <button
        onClick={handleSaveClick}
        className={`w-full py-2 px-4 bg-emerald-500 text-white rounded-md ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-emerald-600"}`}
        disabled={loading}
      >
        {loading ? "Processing..." : "Save"}
      </button>

      {/* Display the list of uploaded URLs */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4"></h3>
        {urlList.length === 0 ? (
          <p></p>
        ) : (
          <table className="w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2">URL</th>
                <th className="p-2">Is Live</th>
                <th className="p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {urlList.map(urlItem => (
                <tr key={urlItem.id} className="border-t border-gray-300">
                  <td className="p-2">{urlItem.source_name}</td>
                  <td className="p-2">{urlItem.is_live ? "Yes" : "No"}</td>
                  <td className="p-2">
                    <button
                      onClick={() => handleRemoveClick(urlItem.id)}
                      className="py-1 px-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default WebsiteURLInput;
