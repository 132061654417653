import React, { useState, useEffect, useRef } from "react";

import {
  ArrowLeftCircleIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/20/solid";

import ThreadMessages from "./ThreadMessages.jsx";

const Thread = ({
  selectedThread,
  setSelectedThread,
  messages,
  fetchThread,
  chatbotUuid,
  api,
}) => {
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const handleInputChange = e => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    setIsTyping(true);

    try {
      await api.post("/api/save-chat-response", {
        text: newMessage,
        company_id: Number(sessionStorage.getItem("company_id")),
        chatbot_uuid: chatbotUuid,
        conversation_uuid: selectedThread.conversation_uuid,
      });

      fetchThread({ thread: selectedThread });

      setNewMessage("");
    } catch (err) {
      alert("Error sending message, contact us for help.");
      console.error("Failed to send message", err);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div
      className={`flex-grow p-4
        ${!selectedThread && "hidden sm:block"}`}
    >
      <div className="xs:block sm:hidden">
        <ArrowLeftCircleIcon
          onClick={() => setSelectedThread(undefined)}
          aria-hidden="true"
          className="ml-2 h-5 w-5 text-emerald-500"
        />
      </div>
      {selectedThread ? (
        <div className="flex flex-col" style={{ height: "calc(100vh - 98px)" }}>
          <div className="flex-1 overflow-y-auto p-4">
            <h2 className="text-lg font-bold">{selectedThread.title}</h2>
            <div className="mt-2">
              <ThreadMessages messages={messages}></ThreadMessages>
            </div>
            <div ref={messageEndRef} />
          </div>

          <div className="sticky bottom-0 bg-white p-4 border-t border-gray-300">
            <div className="flex items-center space-x-4">
              <div className="relative w-full">
                <textarea
                  className="w-full p-2 border rounded-lg pr-10 resize-none overflow-y-auto"
                  rows={4}
                  placeholder="Type a new message..."
                  value={newMessage}
                  onChange={handleInputChange}
                ></textarea>

                <button
                  className={`absolute right-2 bottom-2 p-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg transition-all duration-200 ease-in-out
      ${isTyping || !newMessage ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSendMessage}
                  disabled={isTyping || !newMessage}
                >
                  <PaperAirplaneIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-gray-500"></div>
      )}
    </div>
  );
};

export default Thread;
