import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from 'react-markdown';
import { v4 as uuidv4 } from "uuid";
import api from "../api";
import { UserIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";

const TestChatbot = () => {
  const [chatbotUuid, setChatbotUuid] = useState(localStorage.getItem("chatbot_uuid") || "");
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const initialized = useRef(false);

  useEffect(() => {
    if (!chatbotUuid) {
      console.error("Chatbot UUID is not found in local storage or URL parameters");
      return;
    }

    const fetchConversations = async () => {
      try {
        const chatbotResponse = await api.get(`/api/chatbot/${chatbotUuid}`);
        const greetingMessage = {
          message: chatbotResponse.data.greeting,
          sentTime: "just now",
          sender: "ChatBot",
          direction: "incoming",
        };
        setMessages([greetingMessage]);

        const response = await api.get(`/api/conversations/${chatbotUuid}?test_chat=true`);
        const fetchedConversations = response.data.conversations;
        setConversations(fetchedConversations);

        if (fetchedConversations.length > 0) {
          const mostRecentConversation = fetchedConversations.reduce((prev, current) => {
            return new Date(prev.last_message_time) > new Date(current.last_message_time) ? prev : current;
          });
          handleConversationClick(mostRecentConversation.conversation_uuid, greetingMessage);
        } else {
          createNewTestConversation(greetingMessage);
        }
      } catch (err) {
        console.error("Failed to fetch conversations", err);
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      fetchConversations();
    }
  }, [chatbotUuid]);

  const handleConversationClick = async (conversation_uuid, initialMessage) => {
    try {
      const response = await api.get(`/api/conversation/${conversation_uuid}`);
      setSelectedConversation(conversation_uuid);
      const chatHistory = response.data.map((entry) => ({
        message: entry.text,
        sentTime: new Date(entry.created_at).toLocaleTimeString(),
        sender: entry.direction === "outgoing" ? "user" : entry.is_human ? "admin" : "ChatBot",
        direction: entry.direction === "outgoing" ? "outgoing" : "incoming",
      }));
      setMessages([initialMessage, ...chatHistory]);
    } catch (err) {
      console.error("Failed to fetch conversation details", err);
    }
  };

  const handleSendMessage = async (message) => {
    const newChatMessage = {
      message,
      direction: "outgoing",
      sender: "user",
      sentTime: new Date().toLocaleTimeString(),
    };
    const newMessages = [...messages, newChatMessage];
    setMessages(newMessages);
    setNewMessage("");
    setIsTyping(true);

    debugger
    try {
      const response = await api.post("/chat", {
        user_input: message,
        company_id: Number(sessionStorage.getItem("company_id")),
        chatbot_uuid: chatbotUuid,
        conversation_uuid: selectedConversation,
      });

      const chatResponse = {
        message: response.data.chat_response,
        direction: "incoming",
        sender: "ChatBot",
        sentTime: new Date().toLocaleTimeString(),
      };

      setMessages([...newMessages, chatResponse]);
    } catch (err) {
      console.error("Failed to send message", err);
    } finally {
      setIsTyping(false);
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const createNewTestConversation = async (initialMessage) => {
    const newConversationUuid = uuidv4();
    try {
      await api.post("/api/create-conversation", {
        conversation_uuid: newConversationUuid,
        chatbot_uuid: chatbotUuid,
        test: true,
      });
      handleConversationClick(newConversationUuid, initialMessage);
    } catch (err) {
      console.error("Failed to create new test conversation", err);
    }
  };

  const handleResetConversation = () => {
    createNewTestConversation(messages[0]);
  };

  return (
    <div className="w-full h-full p-4">
      <h1 className="text-2xl font-bold mb-4">Test Chatbot</h1>
      <div className="relative h-[500px] w-full bg-white border border-gray-200 rounded-lg p-4">
        <div className="flex flex-col h-full overflow-y-auto">
          <div className="flex-grow mb-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex my-2 ${
                  message.direction === "outgoing" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`flex items-center p-4 rounded-lg max-w-[75%] ${
                    message.direction === "outgoing"
                      ? "bg-emerald-500 text-white"
                      : "bg-gray-200 text-gray-900"
                  }`}
                >
                  {message.direction === "outgoing" && (
                    <UserIcon className="h-6 w-6 text-white mr-2 flex-shrink-0" />
                  )}
                  <div>
                    <div><ReactMarkdown>{message.message}</ReactMarkdown></div>
                    <div className="text-xs text-gray-500 mt-1">{message.sentTime}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="relative">
            <textarea
              className="w-full p-2 border rounded-lg pr-10 resize-none"
              rows={4}
              placeholder="Type a new message..."
              value={newMessage}
              onChange={handleInputChange}
            ></textarea>
            <button
              className={`absolute right-2 bottom-2 flex items-center justify-center p-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg ${
                isTyping || !newMessage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => handleSendMessage(newMessage)}
              disabled={isTyping || !newMessage}
            >
              <PaperAirplaneIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <button
        onClick={handleResetConversation}
        className="mt-4 px-4 py-2 bg-emerald-500 text-white rounded hover:bg-emerald-600"
      >
        Reset Conversation
      </button>
    </div>
  );
};

export default TestChatbot;
