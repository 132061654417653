import React from 'react';
import WebsiteURLInput from './WebsiteURLInput';
import GmailSupport from './GmailSupport';
import IntercomSupport from './IntercomSupport';
import FileUpload from './FileUpload';

const Content = () => {
  const [activeTab, setActiveTab] = React.useState('1');

  const handleURLSuccess = () => {
    // Handle success after URL processing (e.g., navigate to another page or show a success message)
  };

  return (
    <div className="w-full px-6 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Content</h1>

      <div className="flex justify-center space-x-6 mb-8">
        <button
          className={`py-2 px-4 rounded ${activeTab === '1' ? 'bg-emerald-500 text-white' : 'bg-gray-300 text-gray-700'} focus:outline-none`}
          onClick={() => setActiveTab('1')}
        >
          Website
        </button>
        <button
          className={`py-2 px-4 rounded ${activeTab === '2' ? 'bg-emerald-500 text-white' : 'bg-gray-300 text-gray-700'} focus:outline-none`}
          onClick={() => setActiveTab('2')}
        >
          Files
        </button>
        <button
          className={`py-2 px-4 rounded ${activeTab === '3' ? 'bg-emerald-500 text-white' : 'bg-gray-300 text-gray-700'} focus:outline-none`}
          onClick={() => setActiveTab('3')}
        >
          Gmail
        </button>
        <button
          className={`py-2 px-4 rounded ${activeTab === '4' ? 'bg-emerald-500 text-white' : 'bg-gray-300 text-gray-700'} focus:outline-none`}
          onClick={() => setActiveTab('4')}
        >
          Intercom
        </button>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        {activeTab === '1' && <WebsiteURLInput onSuccess={handleURLSuccess} />}
        {activeTab === '2' && <FileUpload />}
        {activeTab === '3' && <GmailSupport />}
        {activeTab === '4' && <IntercomSupport />}
      </div>
    </div>
  );
};

export default Content;
