import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import { AuthProvider } from "./AuthContext";
import Landing from "./onboarding/Landing";
import Signup from "./onboarding/Signup";
import Callback from "./Callback";
import ProtectedRoute from "./ProtectedRoute";
import UseCaseSelection from "./onboarding/UseCaseSelection";
import EnterWebsiteURL from "./onboarding/EnterWebsiteURL";
import SelectContent from "./onboarding/SelectContent";
import GmailSupport from "./onboarding/ingestionSources/GmailSupport";
import IntercomSupport from "./onboarding/ingestionSources/IntercomSupport";
import FileUploadSupport from "./onboarding/ingestionSources/FileUploadSupport";
import SubscriptionPage from "./onboarding/SubscriptionPage";
import DashboardLayout from "./dashboard/DashboardLayout";
import AIInbox from "./dashboard/AIInbox";
import Actions from "./dashboard/Actions";
import TestChatBot from "./dashboard/TestChatBot";
import Settings from "./dashboard/Settings";
import Profile from "./dashboard/Profile";
import Content from "./dashboard/Content";
import Intents from "./dashboard/Intents";
import Account from "./dashboard/Account";
import Integrations from "./dashboard/Integrations";
import PasswordReset from "./onboarding/PasswordReset";
import RecoverPassword from "./onboarding/RecoverPassword";

import { IngestionWizardProvider } from "./onboarding/IngestionWizardContext";
// Import Content component

import "antd/dist/reset.css";


function App() {
  return (
    <AuthProvider>
      <IngestionWizardProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/onboarding" />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/onboarding" element={<Landing />} />
            <Route
              path="/onboarding/signin"
              element={<Signup sign_in={true} />}
            />
            <Route path="/onboarding/signup" element={<Signup />} />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/onboarding/use-case"
              element={<ProtectedRoute component={UseCaseSelection} />}
            />
            <Route
              path="/onboarding/website-url"
              element={<ProtectedRoute component={EnterWebsiteURL} />}
            />
            <Route
              path="/onboarding/select-content"
              element={<ProtectedRoute component={SelectContent} />}
            />
            <Route
              path="/onboarding/gmail-support"
              element={<ProtectedRoute component={GmailSupport} />}
            />
            <Route
              path="/onboarding/intercom-support"
              element={<ProtectedRoute component={IntercomSupport} />}
            />
            <Route
              path="/onboarding/file-upload-support"
              element={<ProtectedRoute component={FileUploadSupport} />}
            />
            <Route
              path="/onboarding/subscription"
              element={<ProtectedRoute component={SubscriptionPage} />}
            />
            <Route
              path="/dashboard"
              element={<ProtectedRoute component={DashboardLayout} />}
            >
              <Route path="ai-inbox" element={<AIInbox />} />
              <Route path="actions" element={<Actions />} />
              <Route path="test" element={<TestChatBot />} />
              <Route path="settings" element={<Settings />} />
              <Route path="content" element={<Content />} />
              <Route path="profile" element={<Profile />} />
              <Route path="intents" element={<Intents />} />
              <Route path="account" element={<Account />} />
              <Route path="integrations" element={<Integrations />} />
            </Route>
          </Routes>
        </Router>
      </IngestionWizardProvider>
    </AuthProvider>
  );
}

export default App;
